import BsFillArrow from "@/public/icons/bsfillArrow.svg"

import Image from "next/image"
import { FC, useCallback, useEffect, useState } from "react"

const imagesArr = [
  "/images/hero/newer/1.webp",
  "/images/hero/newer/2.jpg",
  "/images/hero/newer/3.jpg",
  "/images/hero/newer/4.jpg",
  "/images/hero/newer/5.jpg",
  "/images/hero/newer/6.jpg",
  "/images/hero/newer/7.jpg",
]

const blurredImages = [
  "/images/hero/new/1.webp",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAMklEQVR4nAEnANj/AKSDc9+tlP/Qt//35QCjlo/ayb3CtKqOhn8ACQYDIBkXHRkUGBIPwuQR3PRdE64AAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAMklEQVR4nAEnANj/AHx0p6qf5ZyX4bS5/wCBaUR2bk0EAwAjGx0Az6qJ19Cw1//ap9i6fwoUSdwjJdkAAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAMElEQVR4nGO49P//8U//L/38f/37fwYzU53SwpTuzmYmNgYGAxWG0ECG+AgGXWUGAL1jEKH1oRjkAAAAAElFTkSuQmCC",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAMklEQVR4nAEnANj/AP/77P/46uzUuunl3AAkKhdQRy6ib0PJwbkAVVhaDAMAGwYAeHZpyHgSO9v9qe8AAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAMklEQVR4nAEnANj/AK+km+7j2uPTyuncywCFdWj//vLt49qWjH4AHAAApJ2pqp6nMx4V+pgWoPgoN8UAAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAMklEQVR4nAEnANj/AF07JtaWbtCbdsWZdwC4mof/9ti9o5Kxm4oAJiQhRkE8IR8cCAgIfJQQXydn43YAAAAASUVORK5CYII=",
]

interface HeroCarouselProps {
  images?: string[]
  autoSlideInterval?: number
}

const HeroCarousel: FC<HeroCarouselProps> = ({
  images = imagesArr,
  autoSlideInterval = 2000,
}) => {
  const [currentItem, setCurrentItem] = useState(0)
  const [ItemsLoaded, setItemsLoaded] = useState<number[]>([])

  const next = useCallback(() => {
    setItemsLoaded((prev) => {
      return [...prev, currentItem]
    })
    setCurrentItem((prev) => {
      return (prev + 1) % images.length
    })
  }, [currentItem, images.length])

  const previous = useCallback(() => {
    setItemsLoaded((prev) => {
      return [...prev, currentItem]
    })
    setCurrentItem((prev) => {
      return Math.abs(prev - 1) % images.length
    })
  }, [currentItem, images.length])

  useEffect(() => {
    let interval: NodeJS.Timeout

    interval = setInterval(() => {
      if (currentItem === images.length - 1) {
        setCurrentItem(0)
      } else {
        next()
      }
    }, autoSlideInterval)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [currentItem, images.length, autoSlideInterval, next])

  return (
    <div
      className="relative z-10"
      // onMouseEnter={() => setIsPaused(true)}
      // onMouseLeave={() => setIsPaused(false)}
    >
      <div className={`relative w-full gradient min-h-[calc(100vh-85px)]`}>
        {imagesArr.map((image, index) => (
          <Image
            id={`hero-image-${index}`}
            decoding="async"
            loading={index === 0 ? "eager" : "lazy"}
            key={index}
            src={
              currentItem === index || ItemsLoaded.includes(index)
                ? image
                : blurredImages[index]
            }
            alt={`hero-image-${index + 1}`}
            className={`absolute inset-0 object-cover object-center transition-transform duration-700 ease-in-out`}
            style={{
              transform: `translateX(${(index - currentItem) * 100}%)`,
            }}
            priority={index === 0}
            fill={true}
            width={0}
            height={0}
            sizes="100vw"
          />
        ))}
      </div>
      <ButtonGroup
        next={next}
        previous={previous}
        length={imagesArr.length}
        currentItem={currentItem}
      />
    </div>
  )
}

const ButtonGroup = ({
  next,
  previous,
  length,
  currentItem,
}: {
  next?: () => void
  previous?: () => void
  length: number
  currentItem: number
}) => {
  return (
    <div
      id="test"
      className="absolute bottom-[80px] w-[80%] mx-auto left-0 right-0 flex justify-between lg:justify-end"
    >
      <div className="w-[51.98px] mr-12 relative h-[51.98px]">
        <button
          aria-label="Previous Image"
          className="relative w-full h-full p-3 text-white rounded-full disabled:bg-gray-400 bg-brand"
          onClick={() => {
            previous!()
          }}
          disabled={currentItem === 0}
        >
          <BsFillArrow className="text-white rotate-180 w-7 h-7" />
        </button>
      </div>
      <button
        aria-label="Next Image"
        className="relative p-3 text-white rounded-full disabled:bg-gray-400 bg-brand"
        onClick={() => {
          next!()
        }}
        disabled={currentItem === length - 1}
      >
        <BsFillArrow className="text-white w-7 h-7" />
      </button>
    </div>
  )
}

export default HeroCarousel
